import breem from './img/breem.png';
import jrmy from './img/jrmy.png';
import kai from './img/kai.png';
import manoer from './img/manoer.png';
import pyramide from './img/pyramide.png';
import magix from './img/magix.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="image-container">
          <img src={breem} className="App-logo" alt="logo" />
          <img src={jrmy} className="App-logo" alt="logo" />
          <img src={kai} className="App-logo" alt="logo" />
          <img src={manoer} className="App-logo" alt="logo" />
          <img src={pyramide} className="App-logo" alt="logo" />
          <img src={magix} className="App-logo" alt="logo" />
        </div>
        <p>
          Eat balls or balls eat you...
        </p>
        <a
          className="wholesome"
          href="https://media-cldnry.s-nbcnews.com/image/upload/newscms/2019_40/3036721/191003-2x1-tyler-hightower-ahdeem-tinsley-ew-1031a.jpg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Good Vibes 🙌
        </a>
      </header>
    </div>
  );
}

export default App;
